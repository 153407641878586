<div class="container">
    <div class="row row-cols-2 justify-content-between align-items-start animated fadeIn">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 margin-top">
            <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="4"></li>
                    <li data-target="#carouselExampleCaptions" data-slide-to="5"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="../../../../assets/gallery/gallery1.jpg" class="d-block w-100" alt="...">
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/gallery/gallery2.jpg" class="d-block w-100" alt="...">
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/gallery/gallery3.jpg" class="d-block w-100" alt="...">
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/gallery/gallery4.jpg" class="d-block w-100" alt="...">
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/gallery/gallery5.jpg" class="d-block w-100" alt="...">
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                    </div>
                    <div class="carousel-item">
                        <img src="../../../../assets/gallery/gallery6.jpg" class="d-block w-100" alt="...">
                        <div class="carousel-caption d-none d-md-block">
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
        <div class="col-5 align-self-center col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <img src="../../../../assets/logo_kaza_maya.png" alt="" class="m-auto row justify-content-center animated fadeIn">
            <h1 class="row justify-content-center text-center animated fadeIn">¿Quiénes somos?</h1>
            <p class="row justify-content-center offset-xl-1 text-justify animated fadeIn">Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, eligendi, impedit tempora accusamus facilis ut obcaecati vel consequatur minus maxime reiciendis! Error alias ipsum sequi optio harum eos pariatur temporibus hic autem. Tenetur
            pariatur quas doloribus aperiam, error animi! Repellendus corporis harum aperiam itaque consequatur, exercitationem hic optio numquam amet.</p>
        </div>
</div>