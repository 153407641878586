import { Component, AfterViewInit } from '@angular/core';

import Swiper from '../../../assets/js/swiper.min.js';


@Component({
  selector: 'app-escenografias',
  templateUrl: './escenografias.component.html',
  styleUrls: ['./escenografias.component.scss']
})
export class EscenografiasComponent implements AfterViewInit {

  constructor() { }

  ngAfterViewInit() {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // Params
    let mainSliderSelector = '.main-slider';
    let navSliderSelector = '.nav-slider';
    let interleaveOffset = 0.5;
    
    // Main Slider
    let mainSliderOptions = {
      loop: true,
      speed:1000,
      autoplay:{
        delay:3000
      },
      loopAdditionalSlides: 10,
      grabCursor: true,
      watchSlidesProgress: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        init: function(){
          this.autoplay.stop();
        },
        imagesReady: function(){
          this.el.classList.remove('loading');
          this.autoplay.start();
        },
        slideChangeTransitionEnd: function(){
          let swiper = this,
              captions = swiper.el.querySelectorAll('.caption');
          for (let i = 0; i < captions.length; ++i) {
            captions[i].classList.remove('show');
          }
          swiper.slides[swiper.activeIndex].querySelector('.caption').classList.add('show');
        },
        progress: function(){
          let swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            let slideProgress = swiper.slides[i].progress;
            let innerOffset = swiper.width * interleaveOffset;
            let innerTranslate = slideProgress * innerOffset;
            
            swiper.slides[i].querySelector('.slide-bgimg').style.transform =
              'translateX(' + innerTranslate + 'px)';
          }
        },
        touchStart: function() {
          let swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = '';
          }
        },
        setTransition: function(speed) {
          let swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = speed + 'ms';
            swiper.slides[i].querySelector('.slide-bgimg').style.transition =
              speed + 'ms';
          }
        }
      }
    };
    let mainSlider = new Swiper(mainSliderSelector, mainSliderOptions);
  
    // Navigation Slider
    let navSliderOptions = {
      loop: true,
      loopAdditionalSlides: 10,
      speed:1000,
      spaceBetween: 5,
      slidesPerView: 5,
      centeredSlides : true,
      touchRatio: 0.2,
      slideToClickedSlide: true,
      direction: 'vertical',
      on: {
        imagesReady: function(){
          this.el.classList.remove('loading');
        },
        click: function(){
          mainSlider.autoplay.stop();
        }
      }
    };
  
    let navSlider = new Swiper(navSliderSelector, navSliderOptions);
    // Matching sliders
    mainSlider.controller.control = navSlider;
    navSlider.controller.control = mainSlider;
  }

}
