<body>
    <div class="row h-100">
        <div class="col-md-6 offset-md-3 my-auto text-center">
            <div class="display-4">
                Mensaje enviado.
            </div>
            <a routerLink="home" class="btn btn-primary">
                Volver
            </a>
        </div>
    </div>
</body>