import { Component} from '@angular/core';
import {Router} from '@angular/router';
import { ContactService } from '../services/contact.service';

// validaciones
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Response } from '../models/Response';

// captcha


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  public loading: boolean;

  success: boolean;
  response: string;
  public contactForm: FormGroup;
  recaptcha: any[];


  mail = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };

  private emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  private namePattern: any = /^[a-zA-Z ]*$/;

  constructor(private contactService: ContactService, private router: Router) {
    this.contactForm = this.createForm();
    this.loading = false;
  }

  createForm() {
    return new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern(this.namePattern)]),
      email: new FormControl('', [Validators.required, Validators.minLength(5), Validators.minLength(5), Validators.maxLength(40), Validators.pattern(this.emailPattern)]),
      subject: new FormControl('', [Validators.required, Validators.maxLength(70)]),
      message: new FormControl('', [Validators.required, Validators.maxLength(300)])
    });
  }

  get name() { return this.contactForm.get('name'); }
  get email() { return this.contactForm.get('email'); }
  get subject() { return this.contactForm.get('subject'); }
  get message() { return this.contactForm.get('message'); }


  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    console.log(this.recaptcha);
  }

  saveEmail() {
    this.loading = true;
    if ( this.contactForm.valid ) {

      this.contactService.sendEmail(this.mail, this.recaptcha)
      .subscribe(
        (res: Response) => {
          // console.log(res);
          this.success = res.success;
          this.response = res.msg;
          this.loading = false;
          this.onResetForm();
        },
        // err => console.log('No valido') // On error
      );
    }
  }

  onResetForm() {
    if ( this.success ) {
      this.contactForm.reset();
    }
  }

  onSaveForm() {
    console.log('Guardado');
  }


}
