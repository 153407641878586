<div class="container margin-top-form animated fadeIn">
    <form [formGroup]="contactForm">
        <div class="form-group">
            <label for="name">Nombre</label>
            <input type="text" class="form-control" [(ngModel)] = "mail.name" formControlName="name" name="email" id="name" autofocus>
            <div class="alert alert-danger margin-top-form" *ngIf="name.invalid && (name.dirty || name.touched)">
                <div class="container-error-message" *ngIf="name.errors.required">
                    El nombre es requerido
                </div>
                <div class="container-error-message" *ngIf="name.errors.minlength">
                    El nombre debe tener 3 caracteres mínimo
                </div>
                <div class="container-error-message" *ngIf="name.errors.maxlength">
                    El nombre debe tener 50 caracteres mínimo
                </div>
                <div class="container-error-message" *ngIf="(name.dirty || name.touched) && name.invalid && name.errors.pattern">
                    No se aceptan números ni carácteres especiales.
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="email">Correo Electrónico</label>
            <input type="email" class="form-control" [(ngModel)] = "mail.email" formControlName="email" name="email" id="email" placeholder="nombre@ejemplo.com">
            <div class="alert alert-danger margin-top-form" *ngIf="email.invalid && (email.dirty || email.touched)">
                <div class="container-error-message" *ngIf="email.errors.required">
                    El Email es requerido
                </div>
                <div class="container-error-message" *ngIf="email.errors.minlength">
                    El Email debe tener 5 caracteres mínimo
                </div>
                <div class="container-error-message" *ngIf="email.errors.maxlength">
                    El Email puede tener 40 caracteres máximo
                </div>
                <div class="container-error-message" *ngIf="(email.dirty || email.touched) && email.invalid && email.errors.pattern">
                    El Email no es válido
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="subject">Asunto</label>
            <input type="email" class="form-control" [(ngModel)] = "mail.subject" formControlName="subject" name="subject" id="subject">
            <div class="alert alert-danger margin-top-form" *ngIf="subject.invalid && (subject.dirty || subject.touched)">
                <div class="container-error-message" *ngIf="subject.errors.required">
                    El Asunto es requerido
                </div>
                <div class="container-error-message" *ngIf="subject.errors.maxlength">
                    El asunto puede tener 70 caracteres máximo
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="messaje">Mensaje</label>
            <textarea class="form-control" id="messaje" [(ngModel)] = "mail.message"  formControlName="message" name="message" rows="6"></textarea>
            <div class=" alert alert-danger margin-top-form" *ngIf="message.invalid && (message.dirty || message.touched)">
                <div class="container-error-message" *ngIf="message.errors.required">
                    El mensaje es requerido
                </div>
                <div class="container-error-message" *ngIf="message.errors.maxlength">
                    El nombre debe tener 300 caracteres máximo
                </div>
            </div>
        </div>
        <div class="form-group row justify-content-center">
            <re-captcha (resolved)="resolved($event)" siteKey="6LfMkAAVAAAAAFaN6os3bv0SBiKE0QfSMrjsJC_f"></re-captcha>
            <div class="alert alert-success margin-top-form recaptcha" *ngIf="success">{{ response }}</div>
            <div class="alert alert-danger margin-top-form recaptcha" *ngIf="!success && response !== undefined">{{ response }}</div>
        </div>
        <div class="form-group row justify-content-center">
            <img src="../../../assets/spinner.gif" *ngIf="loading">
        </div>
        <input type="submit" class="btn btn-primary mb-2 form-group" value="Enviar" (click)="saveEmail()" [disabled]="!contactForm.valid">
    </form>
</div>