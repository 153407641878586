import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

// components
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { SuccessComponent } from './components/success/success.component';
import { ArquitecturaCorporativaComponent } from './components/arquitectura-corporativa/arquitectura-corporativa.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { StandCorporativoComponent } from './components/stand-corporativo/stand-corporativo.component';
import { DisplayPuntoVentaComponent } from './components/display-punto-venta/display-punto-venta.component';
import { EscenografiasComponent } from './components/escenografias/escenografias.component';

// services
import { ContactService } from './components/services/contact.service';

// forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// captcha
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    ContactComponent,
    SuccessComponent,
    ArquitecturaCorporativaComponent,
    StandCorporativoComponent,
    DisplayPuntoVentaComponent,
    EscenografiasComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RecaptchaModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [ContactService],
  bootstrap: [AppComponent]
})
export class AppModule { }
