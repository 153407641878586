import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ContactComponent } from './components/contact/contact.component';
import { SuccessComponent } from './components/success/success.component';
import { ArquitecturaCorporativaComponent } from './components/arquitectura-corporativa/arquitectura-corporativa.component';
import { StandCorporativoComponent } from './components/stand-corporativo/stand-corporativo.component';
import { DisplayPuntoVentaComponent } from './components/display-punto-venta/display-punto-venta.component';
import { EscenografiasComponent } from './components/escenografias/escenografias.component';



const ROUTES: Routes = [
  { path: 'home', component: HomeComponent},
  { path: 'architecture', component: ArquitecturaCorporativaComponent},
  { path: 'stand', component: StandCorporativoComponent},
  { path: 'display', component: DisplayPuntoVentaComponent},
  { path: 'scenes', component: EscenografiasComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'success', component: SuccessComponent},
  { path: '**', pathMatch: 'full', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
