import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Email } from '../models/Email';
@Injectable({
  providedIn: 'root'
})
export class ContactService {

  // API_URI = 'https://mailer-kaza-maya.herokuapp.com';
  // API_URI = 'http://localhost:5000/mailer-kaza-maya-c1273/us-central1/mailer';
  API_URI = 'https://us-central1-mailer-kaza-maya-c1273.cloudfunctions.net/mailer';

  constructor( private http: HttpClient ) {}

  sendEmail( email: Email, captcha: any[] ) {
    console.log({email, captcha});
    return (this.http.post(`${this.API_URI}/send-email`, {email, captcha}));
  }
}
