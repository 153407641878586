<div class="container">
    <div class="row justify-content-around align-items-center">
        <!-- <div class="logo">
            <img src="../../../../assets/logo_kaza_maya.png" alt="" class="logo">
        </div> -->
        <div class="swiper-container main-slider loading">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <figure class="slide-bgimg" style="background-image:url(../../../assets/expo/expo-aneas-1.jpg)">
                        <img src="../../../assets/expo/expo-aneas-1.jpg" class="entity-img" />
                    </figure>
                    <div class="content">
                        <p class="title">Alexis Berry</p>
                        <span class="caption">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                    </div>
                </div>
                <div class="swiper-slide">
                    <figure class="slide-bgimg" style="background-image:url(../../../assets/expo/expo-aneas2.jpg)">
                        <img src="../../../assets/expo/expo-aneas2.jpg" class="entity-img" />
                    </figure>
                    <div class="content">
                        <p class="title">Billie Pierce</p>
                        <span class="caption">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                    </div>
                </div>
                <div class="swiper-slide">
                    <figure class="slide-bgimg" style="background-image:url(../../../assets/expo/expo-aneas-3.jpg)">
                        <img src="../../../assets/expo/expo-aneas-3.jpg" class="entity-img" />
                    </figure>
                    <div class="content">
                        <p class="title">Trevor Copeland</p>
                        <span class="caption">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                    </div>
                </div>
                <div class="swiper-slide">
                    <figure class="slide-bgimg" style="background-image:url(../../../assets/expo/expo-abastur.jpg)">
                        <img src="../../../assets/expo/expo-abastur.jpg" class="entity-img" />
                    </figure>
                    <div class="content">
                        <p class="title">Shaun Matthews</p>
                        <span class="caption">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                    </div>
                </div>
                <div class="swiper-slide">
                    <figure class="slide-bgimg" style="background-image:url(../../../assets/expo/expo-atam.jpg)">
                        <img src="../../../assets/expo/expo-atam.jpg" class="entity-img" />
                    </figure>
                    <div class="content">
                        <p class="title">Bernadette Newman</p>
                        <span class="caption">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                    </div>
                </div>
            </div>
            <!-- If we need navigation buttons -->
            <div class="swiper-button-prev swiper-button-white"></div>
            <div class="swiper-button-next swiper-button-white"></div>
        </div>

        <!-- Thumbnail navigation -->
        <div class="swiper-container nav-slider loading">
            <div class="swiper-wrapper" role="navigation">
                <div class="swiper-slide">
                    <figure class="slide-bgimg" style="background-image:url(../../../assets/expo/expo-aneas-1.jpg)">
                        <img src="../../../assets/expo/expo-aneas-1.jpg" class="entity-img" />
                    </figure>
                    <div class="content">
                        <p class="title">Shaun Matthews</p>
                    </div>
                </div>
                <div class="swiper-slide">
                    <figure class="slide-bgimg" style="background-image:url(../../../assets/expo/expo-aneas2.jpg)">
                        <img src="../../../assets/expo/expo-aneas2.jpg" class="entity-img" />
                    </figure>
                    <div class="content">
                        <p class="title">Alexis Berry</p>
                    </div>
                </div>
                <div class="swiper-slide">
                    <figure class="slide-bgimg" style="background-image:url(../../../assets/expo/expo-aneas-3.jpg)">
                        <img src="../../../assets/expo/expo-aneas-3.jpg" class="entity-img" />
                    </figure>
                    <div class="content">
                        <p class="title">Billie Pierce</p>
                    </div>
                </div>
                <div class="swiper-slide">
                    <figure class="slide-bgimg" style="background-image:url(../../../assets/expo/expo-abastur.jpg)">
                        <img src="../../../assets/expo/expo-abastur.jpg" class="entity-img" />
                    </figure>
                    <div class="content">
                        <p class="title">Trevor Copeland</p>
                    </div>
                </div>
                <div class="swiper-slide">
                    <figure class="slide-bgimg" style="background-image:url(../../../assets/expo/expo-atam.jpg)">
                        <img src="../../../assets/expo/expo-atam.jpg" class="entity-img" />
                    </figure>
                    <div class="content">
                        <p class="title">Bernadette Newman</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row container justify-content-center margin-top animated fadeIn">
            <h1 class="animated fadeIn">¿Quiénes somos?</h1>
        </div>

        <div class="row container animated fadeIn">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, eligendi, impedit tempora accusamus facilis ut obcaecati vel consequatur minus maxime reiciendis! Error alias ipsum sequi optio harum eos pariatur temporibus hic autem. Tenetur
                pariatur quas doloribus aperiam, error animi! Repellendus corporis harum aperiam itaque consequatur, exercitationem hic optio numquam amet.</p>
        </div>
    </div>