/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./success.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./success.component";
var styles_SuccessComponent = [i0.styles];
var RenderType_SuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuccessComponent, data: {} });
export { RenderType_SuccessComponent as RenderType_SuccessComponent };
export function View_SuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "body", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "col-md-6 offset-md-3 my-auto text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "display-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Mensaje enviado. "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "btn btn-primary"], ["routerLink", "home"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" Volver "]))], function (_ck, _v) { var currVal_2 = "home"; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).target; var currVal_1 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); }); }
export function View_SuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-success", [], null, null, null, View_SuccessComponent_0, RenderType_SuccessComponent)), i1.ɵdid(1, 114688, null, 0, i4.SuccessComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuccessComponentNgFactory = i1.ɵccf("app-success", i4.SuccessComponent, View_SuccessComponent_Host_0, {}, {}, []);
export { SuccessComponentNgFactory as SuccessComponentNgFactory };
