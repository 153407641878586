import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ContactService {
    constructor(http) {
        this.http = http;
        // API_URI = 'https://mailer-kaza-maya.herokuapp.com';
        // API_URI = 'http://localhost:5000/mailer-kaza-maya-c1273/us-central1/mailer';
        this.API_URI = 'https://us-central1-mailer-kaza-maya-c1273.cloudfunctions.net/mailer';
    }
    sendEmail(email, captcha) {
        console.log({ email, captcha });
        return (this.http.post(`${this.API_URI}/send-email`, { email, captcha }));
    }
}
ContactService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactService_Factory() { return new ContactService(i0.ɵɵinject(i1.HttpClient)); }, token: ContactService, providedIn: "root" });
